.navbarContainer {
    box-shadow: 0 7px 19px 0 #c6cbe0;
    height: 100vh;
    padding: 15px;
}

.dropdownContainer {
    background-color: #FEFEFF;
    padding: 15px;
    max-height: 300px;
    overflow: auto;
}