
.roleBox {
    background-color: #C8EBFF;
    color: #1280BE;
    font-weight: bold;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding:5px;
}
.filter-selected {
    border-radius: 8px;
    background-color: #E5F0FC;
    color: #5D5FEF;
    font-weight: 400;
    padding: 8px;
    font-size: 14px;
}

.filter-not-selected {
    border-radius: 8px;
    color: #000000;
    font-weight: 400;
    padding: 8px;
    font-size: 14px;
}

.filter-not-selected:hover {
    background-color: #E5F0FC;
    color: #5D5FEF;
}