.addFileButton {
    background-color: #FAFBFF;
    width: 100%;
    border-radius: 10px;
    font-weight: bold;
    color: #93919A;
    padding: 12px;
    border: 0;
    text-align: center;
    border: 1px solid #E6E6E8 ;
    cursor: pointer;
  }
  .addFileButton .MuiTypography-root {
    margin: 0;        
    padding: 0;        
    display: inline;   
    font-size: inherit; 
    line-height: inherit; 
  } 
  .addFileButton:enabled {
    cursor: pointer;
  }
  
  .addFileButton:disabled {
    opacity: 0.5;
  }

  .CommentContainer {
    background-color: #FAFBFF ;
    /* border-bottom: 1px solid #C3C2C7;
    border-left: 1px solid #C3C2C7;
    border-right: 1px solid #C3C2C7; */
    border: 1px solid #C3C2C7;
    border-radius: 16px;
  }

  .messagesTabActive{
    border-top: 1px solid #C3C2C7;
    border-right: 2px solid #C3C2C7;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    color: black;
    font-weight: 700px;
    padding: 10px;
  }

  .messagesTabInActive {
    border-top: 1px solid #C3C2C7;
    border-top-left-radius: 16px;
    padding: 10px;
    cursor:pointer
  }

  .historyTabActive{
    border-top: 1px solid #C3C2C7;
    border-right: 2px solid #C3C2C7;
    border-left: 2px solid #C3C2C7;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    color: black;
    font-weight: 700px;
    padding: 10px;
  }

  .historyTabInActive {
    border-top: 1px solid #C3C2C7;
    border-top-right-radius: 16px;
    padding: 10px;
    cursor:pointer
  }

  .new-task {
    border-radius: 4px;
    background-color: #CCF0EB;
    color: #1280BE;
    font-weight: 700;
    padding: 10px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .open-task {
    border-radius: 4px;
    background-color: #FBE6B1;
    color: #9F7E3A;
    font-weight: 700;
    padding: 10px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inprogress-task {
    border-radius: 4px;
    background-color: #CCF0EB;
    color: #00B69B;
    font-weight: 700;
    padding: 10px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overdue-task {
    border-radius: 4px;
    background-color: #EF4737;
    color: #FEFEFF;
    font-weight: 700;
    padding: 10px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .completed-task {
    border-radius: 4px;
    background-color: #EAE9FC;
    color: #6853A9;
    font-weight: 700;
    padding: 10px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .closed-task {
    border-radius: 4px;
    background-color: #1F845A;
    color: white;
    font-weight: 700;
    padding: 10px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upcoming-task {
    border-radius: 4px;
    background-color: #FFD1F8;
    color: #FF62E7;
    font-weight: 700;
    padding: 10px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .internal-external-button {
    color: #93919A;
    font-weight: bold;
    border: 1px solid #E6E6E8;
    border-radius: 8px;
    width: 110px;
    text-align: center;
    padding: 8px 10px 8px 10px;
    cursor: pointer;
  }

  .internal-external-active {
    background-color: #93919A;
    color: white;
    font-weight: bold;
    border: 1px solid #E6E6E8;
    border-radius: 8px;
    width: 110px;
    text-align: center;
    padding: 8px 10px 8px 10px;
  }

  .filter-selected {
    border-radius: 8px;
    background-color: #E5F0FC;
    color: #5D5FEF;
    font-weight: 400;
    padding: 8px;
    font-size: 14px;
}

.filter-not-selected {
    border-radius: 8px;
    color: #000000;
    font-weight: 400;
    padding: 8px;
    font-size: 14px;
}

.filter-not-selected:hover {
    background-color: #E5F0FC;
    color: #5D5FEF;
}

.moduleBox {
  background-color: #C3C2C7;
  color: black;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding:4px;
}