.loginBg {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-image: url("../images/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.loginContainer {
    background-color: #FDFDFF;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    /* height: 739px; */
    height: 92vh;
    width: 460px;
    padding-top: 25px;
}

.paymentContainer {
    background-color: #5D5FEF;
    border-radius: 10px;
    width: 360px;
    padding-top: 25px;
    color:#FFFFFF;
    padding: 20px;
}

.greyLine {
    background-color: #C3C2C7;
    /* width: 170px; */
    height: 1px
}

.loginButton {
    border: 1px solid #E6E6E8;
    cursor: pointer;
    width: 380px;
    height: 48px;
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    font-size: 14px;
    color: #616161;
    background-color: #F9FAFF;
}

.loginButton:hover {
    background-color: #F7F9FF;
    border: 1px solid #93919A;
}

.loginButton:disabled {
    background-color: #e1e3e8;
    cursor: unset;
}
.loginButton:active{
    background-color: #F7F9FF;
    border: 1px solid #7879F1;
}

.proceedButton {
    background-color: #FEFEFF;
    width: 100%;
    border-radius: 10px;
    font-weight: bold;
    color: #FEFEFF;
    padding: 10px;
    border: 0;
    text-align: center;
    color: #5D5FEF;
  }
  
  .proceedButton:enabled {
    cursor: pointer;
  }
  
  .proceedButton:disabled {
    opacity: 0.5;
  }
  