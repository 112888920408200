.profileContainer {
    background-color: #FEFEFF;
    border: 1px solid #E6E6E8;
    border-radius: 0px 8px 8px 8px;
    padding: 16px
}

.tabs {
    border-top: 1px solid #E6E6E8;
    border-right: 1px solid #E6E6E8;
    border-left: 1px solid #E6E6E8;
    font-size: 14px;
    font-weight: bold;
    color: #C3C2C7;
    border-radius: 8px 8px 0px 0px;
    padding: 20px;
    cursor: pointer;
}

.activeTabs {
    border-top: 1px solid #E6E6E8;
    border-right: 1px solid #E6E6E8;
    border-left: 1px solid #E6E6E8;
    font-size: 14px;
    font-weight: bold;
    color: #FEFEFF;
    border-radius: 8px 8px 0px 0px;
    padding: 20px;
    cursor: pointer;
    background-color: #5D5FEF;
}


.roleBox {
    background-color: #C8EBFF;
    color: #1280BE;
    font-weight: bold;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding:5px;
}

.statusBox {
    background-color: #CCF0EB;
    color:  #00B69B;
    font-weight: bold;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding:5px;
}
